import {VueSlideToggle} from 'vue-slide-toggle';

export default {
  name: 'faq-expander',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    VueSlideToggle
  },
  data() {
    return {
      isOpen: false
    }
  }
}