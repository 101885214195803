import faqExpander from "./components/faq-expander/index.vue"
import emptyData from "@/components/empty-data/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name:'faq',
  data(){
    return{
    }
  },
  components:{
    faqExpander,
    emptyData
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  created() {
    if (!this.list.length) {
      this.fetchFaqList();
    }
  },
  computed: {
    ...mapGetters({
      list: 'faq/list',
      isLoading: 'faq/isLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchFaqList: `faq/FAQ_LIST_GET`,
    }),
  },
}
